import logo from './logo.svg';

function App() {
  return (
    <div className="app">
      <img src={logo} className="Cyber Dynamics" alt="logo" />
    </div>
  );
}

export default App;
